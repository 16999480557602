// @ts-nocheck
import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { validDigitRange } from "../../utils/validations";
import { useSnackbar } from "notistack";
import api from "../../api";
import SharedForm from "./SharedForm";
import BasicDialogue from "./BasicDialogue";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  select: {
    paddingLeft: 12,
  },
}));

export const days = [
  {
    name: "30 Days",
    value: 30,
  },
  {
    name: "60 Days",
    value: 60,
  },
  {
    name: "90 Days",
    value: 90,
  },
  {
    name: "365 Days",
    value: 365,
  },
  {
    name: "2 Years",
    value: 730,
  },
  {
    name: "3 Years",
    value: 1095,
  },
  {
    name: "5 Years",
    value: 1825,
  },
];

const license = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
  },
];

export default function AddLicenseModal({
  show,
  handleClose,
  user_id,
  getLicenses,
}) {
  const [daysValue, setDaysValue] = useState(365);
  const [licenseValue, setLicenseValue] = useState(1);
  const [camerasCount, setCamerasCount] = useState("");
  const [licenseKey, setLicenseKey] = useState([]);
  const [contractId, setContractId] = useState("");
  const [successGeneration, SetSuccessGeneration] = useState(false);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeDays = (event) => {
    setDaysValue(event.target.value);
  };

  const handleChangeLicense = (event) => {
    setLicenseValue(event.target.value);
  };

  const createLicenses = () => {
    api
      .post("/public/v1/licenses/batch/", {
        size: licenseValue,
        license: {
          days: daysValue,
          cameras_count: camerasCount,
          user_id: user_id,
          contract_id: contractId.length ? contractId : null,
        },
      })
      .then(({ data }) => {
        setLicenseKey(data);
        getLicenses();
        handleClearForm();
        enqueueSnackbar("Subscription key successfully generated", {
          variant: "success",
          autoHideDuration: 3000,
        });
        SetSuccessGeneration(true);
      });
  };

  const handleClearForm = () => {
    setCamerasCount("");
    setContractId("");
    setDaysValue(365);
    setLicenseValue(1);
    handleClose();
  };

  const Content = (
    <Grid container spacing={2} direction="column">
      <Grid item xs>
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Days</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={daysValue}
            onChange={handleChangeDays}
            classes={{ root: classes.select }}
            endAdornment={
              daysValue !== 365 && (
                <Box mr="15px">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDaysValue(365);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              )
            }
          >
            {days.map((item) => (
              <MenuItem key={item.name} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs>
        <TextField
          name="cameras_count"
          size="small"
          fullWidth
          label="Channels"
          variant="outlined"
          value={camerasCount}
          onChange={(e) => setCamerasCount(e.target.value)}
          error={!validDigitRange(camerasCount) && camerasCount !== ""}
          helperText={
            !validDigitRange(camerasCount) &&
            camerasCount !== "" &&
            "The Channels count field accepts only numbers from 1 to 100"
          }
          placeholder="Please indicate the number of channels"
        />
      </Grid>
      <Grid item xs>
        <TextField
          name="contract_id"
          size="small"
          fullWidth
          label="Contract"
          variant="outlined"
          value={contractId}
          onChange={(e) => setContractId(e.target.value)}
          error={contractId.length > 100 && contractId !== ""}
          helperText={
            contractId.length > 100 &&
            contractId !== "" &&
            "The Contract field cannot exceed 100 characters"
          }
          placeholder="Please indicate the number of contract"
        />
      </Grid>
      <Grid item xs>
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Number of Keys</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={licenseValue}
            onChange={handleChangeLicense}
            classes={{ root: classes.select }}
            endAdornment={
              licenseValue !== 1 && (
                <Box mr="15px">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setLicenseValue(1);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              )
            }
          >
            {license.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const Action = (
    <>
      <Button variant="outlined" color="primary" onClick={handleClearForm}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={createLicenses}
        disabled={!validDigitRange(camerasCount) || contractId.length > 100}
      >
        Generate
      </Button>
    </>
  );

  return (
    <>
      <BasicDialogue
        show={show}
        handleClose={handleClearForm}
        title="Generate New Key"
        Content={Content}
        Action={Action}
      />
      <SharedForm
        initialValue={licenseKey}
        open={successGeneration}
        onClose={() => SetSuccessGeneration((prev) => !prev)}
      />
    </>
  );
}
