// @ts-nocheck
import React, { useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  Paper,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import { useSnackbar } from "notistack";

export default function SharedForm({ initialValue, open, onClose }) {
  return (
    <Dialog open={open}>
      <Paper variant="outlined" style={{ width: 440 }}>
        <Box
          p={2}
          width={1}
          borderBottom={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography align="left">
            Please Copy the Subscription Keys
          </Typography>
          <Box>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onClose && onClose();
              }}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          style={{
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          {initialValue.map((item, index) => (
            <Box
              key={`${item}-${index}`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <ContentSharedForm item={item} />
            </Box>
          ))}
        </Box>
      </Paper>
    </Dialog>
  );
}

const ContentSharedForm = ({ item }) => {
  const [copy, setCopy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <TextField
        fullWidth
        size="small"
        label="Subscription Key"
        variant="outlined"
        value={item.id}
        disabled
      />
      <Button
        variant={copy ? "outlined" : "contained"}
        color="primary"
        style={{ marginLeft: 8 }}
        onClick={() => {
          enqueueSnackbar("Subscription Key copied", { variant: "success" });
          navigator.clipboard.writeText(item.id);
          setCopy(true);
        }}
      >
        Copy
      </Button>
    </>
  );
};
