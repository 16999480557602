// @ts-nocheck
import React from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  Popover,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { onlyNumbersValidation, validUUID } from "../utils/validations";

const searchBy = [
  {
    name: "Company",
  },
  {
    name: "Company Contact",
  },
  {
    name: "Key (Complete Coincidence)",
    tooltip: true,
  },
  {
    name: "ID (Complete Coincidence)",
    tooltip: true,
  },
  {
    name: "Contract",
  },
];

export const SearchFilters = ({ value, setValue, setRadio, radio }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleChange = (event) => {
    setRadio(event.target.value);
  };

  return (
    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          onClick={(event) => setAnchorEl(event.target)}
          style={{ cursor: "pointer", whiteSpace: "nowrap" }}
          focused={false}
        >
          <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            Search <FormatListBulletedIcon />
          </Box>
        </FormLabel>
        <Popover
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={() => setAnchorEl(null)}
        >
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={radio}
            onChange={handleChange}
            style={{ padding: 10 }}
          >
            {searchBy.map((item, index) => (
              <FormControlLabel
                value={item.name}
                control={<Radio color="primary" />}
                label={item.name}
                key={`${item.name}-${index}`}
                onClick={() => setAnchorEl(null)}
              />
            ))}
          </RadioGroup>
        </Popover>
      </FormControl>
      <TextField
        label={`by ${radio}`}
        id="standard-start-adornment"
        variant="standard"
        fullWidth
        style={{ width: 350 }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={radio === ""}
        error={
          (radio === "ID (Complete Coincidence)" &&
            !onlyNumbersValidation(value) &&
            value !== "") ||
          (radio === "Key (Complete Coincidence)" &&
            !validUUID(value) &&
            value !== "")
        }
        helperText={
          (radio === "ID (Complete Coincidence)" &&
            !onlyNumbersValidation(value) &&
            value !== "" &&
            "Incorrect Subscription ID") ||
          (radio === "Key (Complete Coincidence)" &&
            !validUUID(value) &&
            value !== "" &&
            "Incorrect Key")
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: value.length > 0 && (
            <IconButton position="end" onClick={() => setValue("")}>
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};
