// @ts-nocheck
import React, { useMemo, useState } from "react";
import BasicDialogue from "./BasicDialogue";
import { TableCell, Typography, Button } from "@material-ui/core";
import api from "../../api";
import { DataGrid } from "../table/DataGrid";
import { timeConverter } from "../../utils/validations";

const events = {
  extend: "Extend",
  create: "Generate License",
  activate: "Activate",
  edit: "Edit",
};

const getValueEvents = (value, directory) => {
  const { action_type, data_after, data_before } = value;
  if (action_type !== "edit") {
    return directory[action_type];
  } else {
    if (data_after.contract_id !== data_before.contract_id) {
      return "Edit Contract";
    }
    if (data_after.days !== data_before.days) {
      return "Edit Number of Days";
    }
    if (data_after.cameras_count !== data_before.cameras_count) {
      return "Edit Number of Channels";
    }
    if (!data_after.is_enabled && data_before.is_enabled) {
      return "Suspended";
    }
    if (data_after.is_enabled && !data_before.is_enabled) {
      return "Resumed";
    } else {
      return directory[action_type];
    }
  }
};

const generateGridData = (result) => {
  const gridColumns = [
    {
      name: "Event",
      alignPosition: "center",
      CellRenderer: (value) => {
        return (
          <TableCell align="center">
            <Typography>{getValueEvents(value, events)}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "User",
      alignPosition: "center",
      CellRenderer: (value) => {
        const { user_first_name, user_last_name, user_email } = value;
        return (
          <TableCell align="center">
            <Typography>{`${user_first_name} ${user_last_name}`}</Typography>
            <Typography>{user_email}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Date",
      alignPosition: "center",
      CellRenderer: (value) => {
        const date = timeConverter(value.created_at);

        return (
          <TableCell align="center">
            <Typography>{date}</Typography>
          </TableCell>
        );
      },
    },
  ];

  const gridRows = result;

  return {
    gridColumns,
    gridRows,
  };
};

const HistoryModal = ({ show, handleClose, licenseId }) => {
  const [showLoader, setShowLoader] = useState(true);
  const [history, setHistory] = useState({ gridColumns: [], gridRows: [] });

  // eslint-disable-next-line
  const getHistory = useMemo(() => {
    setShowLoader(true);
    api
      .post("public/v1/licenses/history/", {
        filters: { license_id: licenseId },
        orderings: ["created_at"],
        pagination: {
          pgoffset: 0,
          pgsize: -1,
        },
      })
      .then(({ data }) => {
        if (!data) return;
        const { items } = data;
        const dataForGrid = generateGridData(items);
        setHistory(dataForGrid);
        setShowLoader(false);
      });
  }, [licenseId]);

  const Content = (
    <DataGrid
      {...history}
      data={history.gridRows.length > 0}
      isLoading={showLoader}
      isModalTable
    />
  );

  const Action = (
    <Button variant="outlined" color="primary" onClick={handleClose}>
      Cancel
    </Button>
  );

  return (
    <>
      <BasicDialogue
        show={show}
        handleClose={handleClose}
        title="History"
        Content={Content}
        Action={Action}
        custumWidth={480}
      />
    </>
  );
};

export default HistoryModal;
