// @ts-nocheck
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import { makeStyles, createStyles } from "@material-ui/core/styles";
// import colors from "./theme";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { NavLink, useLocation } from "react-router-dom";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      fontWeight: "bold",
    },
    link: {
      color: "inherit",
      textDecoration: "unset",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      // background: colors.dark2,
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "flex",
      justifyContent: "space-between",
    },
    drawerOpen: {
      overflow: "hidden",
      border: "none",
      display: "flex",
      justifyContent: "space-between",
      // background: colors.dark4,
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      border: "none",
      // background: colors.dark4,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      display: "flex",
      justifyContent: "space-between",
      width: "64px",
    },
    bottomList: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 10px 0 1rem",
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    listItem: {
      padding: "10px 18px",
      height: "52px",
    },
    toolbarBack: {
      width: "2rem",
      height: "2rem",
    },
    menuButton: {},
  })
);

const useMetapixTooltip = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14,
  },
}));

function MetapixTooltip(props: TooltipProps) {
  const classes = useMetapixTooltip();

  return <Tooltip classes={classes} {...props} />;
}

const routes = (UserRole) =>
  UserRole !== "reseller"
    ? [
        {
          name: "Keys",
          url: "/",
          icon: DashboardIcon,
          visible: () => true,
          exact: true,
        },
        {
          name: "Users",
          url: "/integrators",
          icon: PeopleIcon,
          visible: () => true,
          exact: true,
        },
      ]
    : [
        {
          name: "Keys",
          url: "/",
          icon: DashboardIcon,
          visible: () => true,
          exact: true,
        },
      ];

export default function RouteLeftMenu({ openMenu, currentUserRole }) {
  const location = useLocation();
  const classes = useStyles();
  return (
    <>
      <List>
        {routes(currentUserRole)
          .filter((item) => item.visible(location))
          .map((item, index) => (
            <MetapixTooltip title={item.name} key={index} placement="top">
              <NavLink
                key={item.name}
                className="MuiMenuLink"
                activeClassName="active"
                exact={item.exact}
                to={item.url}
              >
                <ListItem
                  button
                  classes={{
                    gutters: clsx(classes.listItem, {
                      [classes.listItemActive]: openMenu,
                    }),
                  }}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <item.icon className={classes.menuIcon} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ noWrap: true }}
                    primary={item.name}
                  />
                </ListItem>
              </NavLink>
            </MetapixTooltip>
          ))}
      </List>
    </>
  );
}
